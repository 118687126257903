    import axiosIns from '@/api/axios'

export const GET_CHEMICAL = (args) => {
    return axiosIns.get(`/ih/management/chemical?${args}`)
}

// export const ADD_CHEMICAL = (args) => {
//     return axiosIns.post(`/ih/management/chemical`, args)
// }
export const ADD_CHEMICAL = (args) => {
    return axiosIns.post(`/ih/management/chemical`, args, {
        headers: {
            "Content-Type": "multipart/form-data"
        }
    })
}

export const DEL_CHEMICAL = (id) => {
    return axiosIns.delete(`/ih/management/chemical?id=${id}`)
}

export const UPDATE_CHEMICAL  = (args) => {
    return axiosIns.put(`/ih/management/chemical`, args)
}

export const EXPORT_CHEMICAL = (id) => {
    return axiosIns.get(`/ih/management/chemical/export?identification_id=${id}`)
}