<template>
  <!-- HEADER -->
  <div class="header">
    <div class="container-fluid">
      <!-- Body -->
      <div class="mt-6">
        <!-- Title -->
        <div class="card">
          <div class="card-body">
            <nav aria-label="breadcrumb">
              <ol class="breadcrumb">
                <li class="breadcrumb-item">
                  {{ $t("IH Management") }}
                </li>
                <li class="breadcrumb-item fw-bolder">Chemical</li>
              </ol>
            </nav>
          </div>
        </div>
      </div>
      <!-- Table -->

      <div class="card mt-5">
        <div class="card-header">
          <!-- Title -->
          <h4 class="card-header-title">{{ $t("IH Chemical") }}</h4>
          <!-- Button -->
          <b-button v-b-modal.modal-1 variant="primary" @click="isAdd = true">
            <span class="fe fe-file-plus"></span>
            {{ $t("Chemical ") }}
          </b-button>
        </div>
        <div class="cart">
          <div class="card-body">
            <div class="d-flex justify-content-between mb-3">
              <div class="d-flex" v-if="filterCompany == 1">
                <label class="align-self-center mb-0 mr-3">Company</label>
                <v-select
                  :options="selectCompanyOption()"
                  v-model="body.company_id"
                  label="company_name"
                  style="min-width: 240px"
                  :reduce="(e) => e.id"
                  @input="getChemical()" />
              </div>
              <div class="d-flex">
                <label class="align-self-center mb-0 mr-3">Search</label>
                <b-form-input
                  class="w-auto"
                  v-model="body.searchQuery"
                  placeholder="Type Here..."
                  @change="getChemical()"></b-form-input>
              </div>
            </div>
            <!-- :rows="companyDatas.rows"
                        :total-rows="companyDatas.totalRecords" -->
            <!-- {{chemicalData.data}} -->
            <vue-good-table
              mode="remote"
              :line-numbers="true"
              :columns="columns"
              :rows="chemicalData.rows"
              :total-rows="chemicalData.totalRecords"
              :sort-options="{
                enabled: true
              }"
              :pagination-options="{
                enabled: true,
                perPage: body.perPage
              }"
              style-class="vgt-table table table-bordered"
              @on-sort-change="onSortChange"
              :isLoading.sync="isLoading"
              @on-page-change="onPageChange"
              @on-per-page-change="onPageChange">
              <!-- Loading -->
              <div slot="emptystate" class="text-center font-size-14">
                No data available
              </div>
              <template slot="table-row" slot-scope="props">
                <template v-if="props.column.field === 'chemicalName'">
                  {{ getChemicalNameRows(props.row) }}
                </template>
                <template v-else-if="props.column.field === 'hazardName'">
                  {{ getHazardNameRows(props.row) }}
                </template>
                <template v-else-if="props.column.field === 'materialType'">
                  {{ getMaterialTypeRows(props.row) }}
                </template>
                <span v-else-if="props.column.field == 'action'">
                  <b-button
                    class="no-wrap btn-pill"
                    variant="warning"
                    size="sm"
                    @click="updateData(props.row)">
                    <span class="fe fe-edit"></span>
                  </b-button>
                  <b-button
                    class="no-wrap ml-2"
                    variant="danger"
                    style="margin-left: 5px"
                    size="sm"
                    @click="deleteData(props.row)">
                    <span class="fe fe-trash-2"></span>
                  </b-button>
                  <b-button
                    class="no-wrap ml-2"
                    variant="outline-primary"
                    style="margin-left: 5px"
                    size="sm"
                    @click="downloadData(props.row)">
                    <span class="fe fe-download"></span>
                  </b-button>
                </span>
                <!-- <span v-else-if="props.column.field == 'is_active'">
                                    <span v-if="props.row.is_active === true" class="badge bg-success-soft">ACTIVE</span>
                                    <span v-if="props.row.is_active === false" class="badge bg-danger-soft">INACTIVE</span>
                                </span> -->
              </template>
              <template slot="pagination-bottom" slot-scope="props">
                <div class="d-flex justify-content-end">
                  <div>
                    <b-pagination
                      :value="1"
                      :total-rows="1"
                      :per-page="body.perPage"
                      first-number
                      last-number
                      align="right"
                      prev-class="prev-item"
                      next-class="next-item"
                      class="m-3"
                      @input="
                        (value) => props.pageChanged({ currentPage: value })
                      ">
                    </b-pagination>
                  </div>
                </div>
              </template>
            </vue-good-table>
          </div>
          <!-- Link -->
        </div>
      </div>

      <!-- Modal -->
      <b-modal
        id="modal-1"
        centered
        hide-footer
        :title="$t('Chemical Form')"
        @hidden="onHidden()"
        size="xl">
        <form @submit.prevent="saveAdd()" v-if="isAdd === true">
          <div class="row">
            <div class="col-lg-6">
              <div class="form-group">
                <label>{{ $t("Form Number") }}</label>
                <input
                  v-model="bodyAdd.form_number"
                  type="text"
                  class="form-control"
                  v-validate="'required'"
                  name="Form Number" />
                <span
                  class="text-sm text-danger"
                  v-show="errors.has('Form Number')"
                  >{{ errors.first("Form Number") }}</span
                >
              </div>

              <div class="form-group">
                <label>{{ $t("Date") }}</label>
                <input
                  v-validate="'required'"
                  name="Date"
                  type="date"
                  class="form-control"
                  placeholder="Date"
                  v-model="bodyAdd.identification_date" />
                <span class="text-sm text-danger" v-show="errors.has('Date')">{{
                  errors.first("Date")
                }}</span>
              </div>
              <div class="form-group">
                <label>{{ $t("File SDS") }}</label>
                <!-- v-validate="'required'" -->
                <input
                  type="file"
                  class="form-control"
                  accept="image/*,.pdf"
                  ref="fileUpload"
                  @change="fileUploader" />
                <span class="text-sm text-danger" v-show="errors.has('file')">{{
                  errors.first("file")
                }}</span>
              </div>
            </div>

            <div class="col-lg-6">
              <div class="card">
                <div class="card-header">
                  <!-- Title -->
                  <h4 class="card-header-title">Chemical Material</h4>

                  <!-- Link -->
                  <a href="#!" class="small" @click="createNew()">Add</a>
                </div>
                <div class="card-body">
                  <!-- List group -->
                  <div class="list-group list-group-flush my-n3">
                    <div
                      class="list-group-item"
                      v-for="(unit, index) in chemical"
                      :key="index">
                      <div class="row align-items-center">
                        <!-- Avatar -->
                        <div class="form-group mb-0">
                          <label>{{ $t("Chemical Name") }}</label>
                          <v-select
                            :options="chemicalDataType.data"
                            label="chemical_material_name"
                            v-validate="'required'"
                            name="Chemical Name"
                            v-model="unit.name"
                            @input="changeChemical(index)" />
                          <span
                            class="text-sm text-danger"
                            v-show="errors.has('Chemical Name')"
                            >{{ errors.first("Chemical Name") }}</span
                          >
                        </div>
                        <div class="form-group mb-0">
                          <label>{{ $t("Hazard Name") }}</label>
                          <input
                            type="text"
                            class="form-control"
                            disabled
                            :value="unit.hazard_name" />
                        </div>
                        <div class="form-group mb-0">
                          <label>{{ $t("Material Type") }}</label>
                          <input
                            type="text"
                            class="form-control"
                            disabled
                            :value="unit.material_type" />
                        </div>
                        <div class="form-group mb-0">
                          <label>{{ $t("Location") }}</label>
                          <input
                            type="text"
                            class="form-control"
                            v-model="unit.location" />
                        </div>
                        <div class="form-group mb-0">
                          <label>{{ $t("Estimated Qty") }}</label>
                          <input
                            type="text"
                            class="form-control"
                            v-model="unit.estimated_qty" />
                        </div>
                        <div class="form-group mb-0">
                          <label>{{ $t("PIC") }}</label>
                          <input
                            type="text"
                            class="form-control"
                            v-model="unit.pic" />
                        </div>
                        <!-- <div class="col-auto"> -->
                        <span
                          class="text-right"
                          style="cursor: pointer"
                          @click="removeUnit(index)"
                          >Remove</span
                        >
                        <!-- </div> -->
                      </div>
                      <!-- / .row -->
                    </div>
                  </div>
                </div>
                <!-- / .card-body -->
              </div>
            </div>

            <div class="form-group">
              <button
                style="margin-left: auto; float: right"
                class="mt-4 btn btn-primary"
                type="submit"
                v-if="isLoading == false">
                {{ $t("Save") }}
              </button>
              <button
                style="margin-left: auto; float: right"
                class="mt-4 btn btn-primary"
                type="button"
                disabled
                v-else>
                <span
                  class="spinner-border spinner-border-sm"
                  role="status"
                  aria-hidden="true"></span>
                Loading...
              </button>
            </div>
          </div>
        </form>

        <form @submit.prevent="saveUpdate()" v-else>
          <div class="row">
            <div class="col-lg-6">
              <div class="form-group">
                <label>{{ $t("Form Number") }}</label>
                <input
                  v-model="bodyUpdate.form_number"
                  type="text"
                  class="form-control"
                  v-validate="'required'"
                  name="Form Number" />
                <span
                  class="text-sm text-danger"
                  v-show="errors.has('Form Number')"
                  >{{ errors.first("Form Number") }}</span
                >
              </div>

              <div class="form-group">
                <label>{{ $t("Date") }}</label>
                <input
                  v-validate="'required'"
                  name="Date"
                  type="date"
                  class="form-control"
                  placeholder="Date"
                  v-model="bodyUpdate.identification_date" />
                <span class="text-sm text-danger" v-show="errors.has('Date')">{{
                  errors.first("Date")
                }}</span>
              </div>
              <div class="form-group" v-if="bodyUpdate.file">
                <label>{{ $t("File") }}</label>
                <div v-if="bodyUpdate.file">
                  <div v-if="isImage(bodyUpdate.file)">
                    <img
                      :src="this.baseUrl + bodyUpdate.file"
                      alt="Uploaded Image"
                      class="img-fluid" />
                  </div>
                  
                  <div v-else-if="isPDF(bodyUpdate.file)">
                    <!-- <a :href="this.baseUrl + bodyUpdate.file" download>{{
                      $t("Download PDF")
                    }}</a> -->
                    <iframe :src="this.baseUrl + bodyUpdate.file" width="100%" height="600px"></iframe>
                  </div>
                </div>
              </div>
            </div>

            <div class="col-lg-6">
              <div class="card">
                <div class="card-header">
                  <!-- Title -->
                  <h4 class="card-header-title">Chemical Material</h4>

                  <!-- Link -->
                  <a href="#!" class="small" @click="createNew()">Add</a>
                </div>
                <div class="card-body">
                  <!-- List group -->
                  <div class="list-group list-group-flush my-n3">
                    <div
                      class="list-group-item"
                      v-for="(unit, index) in chemical"
                      :key="index">
                      <div class="row align-items-center">
                        <!-- Avatar -->
                        <div class="form-group mb-0">
                          <label>{{ $t("Chemical Name") }}</label>
                          <v-select
                            :options="chemicalDataType.data"
                            label="chemical_material_name"
                            v-validate="'required'"
                            name="Chemical Name"
                            v-model="unit.name"
                            @input="changeChemical(index)" />
                          <span
                            class="text-sm text-danger"
                            v-show="errors.has('Chemical Name')"
                            >{{ errors.first("Chemical Name") }}</span
                          >
                        </div>
                        <div class="form-group mb-0">
                          <label>{{ $t("Hazard Name") }}</label>
                          <input
                            type="text"
                            class="form-control"
                            disabled
                            :value="unit.hazard_name" />
                        </div>
                        <div class="form-group mb-0">
                          <label>{{ $t("Material Type") }}</label>
                          <input
                            type="text"
                            class="form-control"
                            disabled
                            :value="unit.material_type" />
                        </div>
                        <div class="form-group mb-0">
                          <label>{{ $t("Location") }}</label>
                          <input
                            type="text"
                            class="form-control"
                            v-model="unit.location" />
                        </div>
                        <div class="form-group mb-0">
                          <label>{{ $t("Estimated Qty") }}</label>
                          <input
                            type="text"
                            class="form-control"
                            v-model="unit.estimated_qty" />
                        </div>
                        <div class="form-group mb-0">
                          <label>{{ $t("PIC") }}</label>
                          <input
                            type="text"
                            class="form-control"
                            v-model="unit.pic" />
                        </div>
                        <!-- <div class="col-auto"> -->
                        <span
                          class="text-right"
                          style="cursor: pointer"
                          @click="removeUnit(index)"
                          >Remove</span
                        >
                        <!-- </div> -->
                      </div>
                      <!-- / .row -->
                    </div>
                  </div>
                </div>
                <!-- / .card-body -->
              </div>
            </div>

            <div class="form-group">
              <button
                style="margin-left: auto; float: right"
                class="mt-4 btn btn-primary"
                type="submit"
                v-if="isLoading == false">
                {{ $t("Save") }}
              </button>
              <button
                style="margin-left: auto; float: right"
                class="mt-4 btn btn-primary"
                type="button"
                disabled
                v-else>
                <span
                  class="spinner-border spinner-border-sm"
                  role="status"
                  aria-hidden="true"></span>
                Loading...
              </button>
            </div>
          </div>
        </form>
      </b-modal>
    </div>
  </div>
</template>

<script>
import chemical from "@/store/hazard/chemical";
import axiosIns from "@/api/axios";
export default {
  name: "IHChemical",
  computed: {
    company() {
      return this.$store.getters["detailManagement/dataCompany"]
        ? this.$store.getters["detailManagement/dataCompany"]
        : [];
    },
    chemicalHazard() {
      return this.$store.getters["chemical/ChemicalData"]
        ? this.$store.getters["chemical/ChemicalData"]
        : [];
    },
    chemicalName() {
      return this.$store.getters["name/NameData"]
        ? this.$store.getters["name/NameData"]
        : [];
    },
    materialType() {
      return this.$store.getters["type/TypeData"]
        ? this.$store.getters["type/TypeData"]
        : [];
    },
    chemicalDataType() {
      return this.$store.getters["name/NameData"]
        ? this.$store.getters["name/NameData"]
        : [];
    },
    companyId() {
      return localStorage.getItem("company");
    },
    chemicalData() {
      return this.$store.getters["chemicalIh/chemicalData"];
    }
  },
  data() {
    return {
      baseURL: process.env.VUE_APP_API_URL,
      bodys: {
        page: 1,
        limit: 1000,
        order: ""
      },
      body: {
        page: 1,
        perPage: 10,
        sort: "",
        searchQuery: "",
        company_id: ""
      },
      bodyAdd: {
        file: "",
        form_number: "",
        identification_date: "",
        company_id: "",
        material: []
      },
      bodyUpdate: null,
      chemical: [],
      isAdd: true,
      isLoading: false,
      filterCompany: "",
      columns: [
        {
          label: "FORM NUMBER",
          field: "form_number",
          thClass: "table-header w-auto text-muted font-weight-bold",
          tdClass: "color-secondary align-middle"
        },
        {
          label: "DATE",
          field: "identification_date",
          thClass: "table-header w-auto text-muted font-weight-bold",
          tdClass: "color-secondary align-middle"
        },
        {
          label: "CHEMICAL NAME",
          field: "chemicalName",
          thClass: "table-header w-auto text-muted font-weight-bold",
          tdClass: "color-secondary align-middle"
        },
        {
          label: "HAZARD NAME",
          field: "hazardName",
          thClass: "table-header w-auto text-muted font-weight-bold",
          tdClass: "color-secondary align-middle"
        },
        {
          label: "MATERIAL TYPE",
          field: "materialType",
          thClass: "table-header w-auto text-muted font-weight-bold",
          tdClass: "color-secondary align-middle"
        },
        {
          label: "ACTION",
          field: "action",
          width: "134px",
          thClass: "table-header text-muted font-weight-bold",
          tdClass: "color-secondary align-middle"
        }
      ]
    };
  },
  mounted() {
    const roles = localStorage.getItem("roles_id");
    this.filterCompany = parseInt(roles) == 1 ? 1 : 0;
    this.baseUrl = process.env.VUE_APP_API_URL;

    this.getMaterialDataName();
    this.getType();
    this.getCompanny();
    // this.getName();
    // this.getType();
    this.getChemical();
  },
  methods: {
    async fileUploader(event) {
      this.bodyAdd.file = event.target.files;
    },
    isImage(file) {
      return /\.(jpg|jpeg|png|gif)$/i.test(file);
    },
    isPDF(file) {
      return /\.pdf$/i.test(file);
    },
    selectCompanyOption() {
      const company = this.company;
      const term = [];
      term.push({
        id: 0,
        company_name: "BIB + Mitra"
      });

      company.map((i) => {
        term.push({
          id: i.id,
          company_name: i.company_name
        });
      });
      return term;
    },

    createNew() {
      const bod = {
        name: {},
        hazard_name: "",
        material_type: "",
        id: "",
        location: "",
        estimated_qty: "",
        pic: ""
      };
      this.chemical.push(bod);
    },

    removeUnit(index) {
      this.chemical.splice(index, 1);
      if (this.isAdd) {
        this.bodyAdd.material.splice(index, 1);
      } else {
        this.bodyUpdate.material.splice(index, 1);
      }
    },

    changeChemical(indx) {
      this.chemical[indx].hazard_name =
        this.chemical[indx].name.chemical_hazard_name;
      this.chemical[indx].material_type =
        this.chemical[indx].name.chemical_material_type_name;
      this.chemical[indx].id =
        this.chemical[indx].name.chemical_material_name_id;
      // this.bodyAdd.material.push(this.chemical[indx].name.chemical_material_name_id)
      // const materialResult = this.chemical.map((e) => {
      //     return e.id;
      // })
      if (this.isAdd) {
        this.bodyAdd.material = this.chemical;
      } else {
        this.bodyUpdate.material = this.chemical;
      }
    },

    // Custom Field
    getChemicalNameRows(data) {
      let arr = [];
      for (let i = 0; i < data.material.length; i++) {
        arr.push(data.material[i].chemical_name);
      }
      return arr.join();
    },
    getHazardNameRows(data) {
      let arr = [];
      for (let i = 0; i < data.material.length; i++) {
        arr.push(data.material[i].hazard_type);
      }

      const filter = arr.filter(function (item, pos) {
        return arr.indexOf(item) == pos;
      });
      return filter.join();
    },
    getMaterialTypeRows(data) {
      let arr = [];
      for (let i = 0; i < data.material.length; i++) {
        arr.push(data.material[i].material_type);
      }

      const filter = arr.filter(function (item, pos) {
        return arr.indexOf(item) == pos;
      });
      return filter.join();
    },

    // HIT API
    getMaterialDataName() {
      this.$store.dispatch("name/getName", this.bodys);
    },

    // Material Type
    getType() {
      this.$store.dispatch("type/getType", this.bodys);
    },

    // Sorting
    onSortChange(params) {
      let sort1 = params[0].field;
      let sort2 = params[0].type;
      this.body.sort = `${sort1},${sort2}`;
      this.getChemical();
    },

    // Page Change
    onPageChange(payload) {
      this.body.limit = payload.currentPerPage;
      this.body.page = payload.currentPage;
      this.getChemical();
    },

    // Get Chemical
    getChemical() {
      this.$store.dispatch("chemicalIh/getChemical", this.body);
    },

    // Action
    onHidden() {
      this.chemical = [];
    },
    saveAdd() {
      this.$validator.validateAll().then(async (res) => {
        if (!res) return false;
        try {
          this.isLoading = true;
          
          const formData = new FormData();
          for (let i = 0; i < this.bodyAdd.material.length; i++) {
            delete this.bodyAdd.material[i].name;
            delete this.bodyAdd.material[i].material_type;
            delete this.bodyAdd.material[i].hazard_name;
          }
          this.bodyAdd.company_id = this.companyId;
          for (const key in this.bodyAdd) {
            if (key === "material") {
              formData.append(key, JSON.stringify(this.bodyAdd[key]));
            } else if (key === "file") {
              formData.append(key, this.bodyAdd[key][0]);
            } else {
              formData.append(key, this.bodyAdd[key]);
            }
          }

          this.$store
            .dispatch("chemicalIh/addChemical", formData)
            .then((resp) => {
              this.$swal({
                title: this.$t("Saved"),
                text: this.$t("Your input has been saved"),
                icon: "success",
                confirmButtonText: this.$t("OK")
              }).then((resp) => {
                (this.bodyAdd.file = ""),
                  (this.bodyAdd.form_number = ""),
                  (this.bodyAdd.identification_date = ""),
                  (this.bodyAdd.company_id = ""),
                  (this.bodyAdd.material = []);
                this.isLoading = false;
                this.getChemical();
                this.$bvModal.hide("modal-1");
              });
            })
            .catch((err) => {
                this.isLoading = false;
              this.$swal.fire("Error!", `${err.response.data.error}`, "error");
            });
        } catch (resp) {}
      });
    },
    updateData(data) {
      console.log("data row=>>", data);
      this.isAdd = false;
      const payload = {
        form_number: data.form_number,
        identification_date: data.identification_date,
        company_id: data.company_id,
        file: data.file
      };
      payload["id"] = data.id;
      this.bodyUpdate = payload;
      this.chemical = data.material.map((e) => ({
        id: e.chemical_id,
        name: this.chemicalDataType.data.find(
          (el) => el.chemical_material_name_id === e.chemical_id
        ),
        hazard_name: e.hazard_type,
        material_type: e.material_type,
        location: e.location,
        estimated_qty: e.estimated_qty,
        pic: e.pic
      }));
      // const materialResult = this.chemical.map((e) => {
      //     return e.id;
      // })
      this.bodyUpdate.material = this.chemical;

      setTimeout(() => {
        this.$bvModal.show("modal-1");
      }, 250);
    },
    saveUpdate() {
      const formData = new FormData();
      for (let i = 0; i < this.bodyUpdate.material.length; i++) {
        delete this.bodyUpdate.material[i].name;
        delete this.bodyUpdate.material[i].material_type;
        delete this.bodyUpdate.material[i].hazard_name;
      }
      //     for (const key in this.bodyUpdate) {
      //   if (key === 'material') {
      //     formData.append(key, JSON.stringify(this.bodyUpdate[key]));
      //   }else if(key === "file") {
      //             formData.append(key, this.bodyUpdate[key][0]);
      //           } else {
      //     formData.append(key, this.bodyUpdate[key]);
      //   }
      // }
      this.$store
        .dispatch("chemicalIh/updateChemical", this.bodyUpdate)
        .then((resp) => {
          this.$swal("Saved", "Your data has been edit", "success").then(
            (resp) => {
              this.getChemical();
              this.$bvModal.hide("modal-1");
            }
          );
        })
        .catch((resp) => {
          this.$swal("Error", "Error while edit data", "error");
        });
    },
    deleteData(data) {
      this.$swal({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#00D97E",
        cancelButtonColor: "#E63757",
        confirmButtonText: "Yes, delete it!"
      }).then((result) => {
        if (result.isConfirmed) {
          this.$store
            .dispatch("chemicalIh/delChemical", data.id)
            .then((resp) => {
              this.getChemical();
              this.$swal("Deleted!", "Your data has been deleted.", "success");
            })
            .catch((resp) => {
              this.$swal("Error", "Error while delete data", "error");
            });
        }
      });
    },
    downloadData(data) {
      this.$store.dispatch("chemicalIh/exportChemical", data.id).then((res) => {
        let link = res;
        if (link.hasOwnProperty("file")) {
          link.file = link.file
            .replace("/hms-test/api", "")
            .replace("/hms/api", "");
          window.open(axiosIns.defaults.baseURL + link.file);
        } else {
          this.$swal("Error", link.data.error, "error");
          console.log(link.data.error);
        }
      });
    },
    getCompanny() {
      this.$store.dispatch("detailManagement/getCompany");
    }
  }
};
</script>
